var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',[_c('v-data-table',{ref:"Documents",attrs:{"headers":_vm.headers,"items":_vm.displayDocuments,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"hide-default-footer":"","show-expand":"","single-expand":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDescription")(item.type,_vm.documentTypes))+" ")]}},{key:"item.validTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.validTo,"DD.MM.YYYY"))+" ")]}},{key:"item.dateOfDeprecation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dateOfDeprecation,"DD.MM.YYYY"))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-6",attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex flex-column ml-8"},_vm._l((item.files),function(file){return _c('div',{key:file.name,staticClass:"d-flex align-center mb-4"},[_c('h4',[_vm._v(_vm._s(file.name))]),_c('v-icon',{staticClass:"ml-auto pointer",attrs:{"color":"blue","small":""},on:{"click":function($event){return _vm.getFile(file.name)}}},[_vm._v("mdi-download")])],1)}),0)])],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$permission('DeleteFiles'))?_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.openDeleteDialog(item)}}},[_vm._v(" "+_vm._s(_vm.$t('common.remove'))+" ")]):_vm._e()]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',{staticClass:"py-4"},[_c('v-card-title',[_c('h3',{staticClass:"primary--text mb-2"},[_vm._v(" "+_vm._s(_vm.$t("companiesDetails.removeFile"))+" ")])]),_c('v-card-actions',{staticClass:"px-8"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secodary","outlined":"","depressed":"","disabled":_vm.loading},on:{"click":_vm.closeDeleteDialog}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"primary","depressed":"","loading":_vm.loading},on:{"click":_vm.deleteFile}},[_vm._v(" "+_vm._s(_vm.$t('common.remove'))+" ")])],1)],1)],1),(_vm.pageCount > 1)?_c('v-pagination',{attrs:{"value":_vm.options.page,"length":_vm.pageCount},on:{"input":function($event){_vm.options.page = $event}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }