<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row v-if="details">
          <v-col cols="6">
            <v-card class="h-100">
              <v-card-text class="pa-10 h-100 d-flex align-start">
                <v-col>
                  <v-row>
                    <div
                      v-if="details.logo && details.logo != ''"
                      class="d-flex align-end"
                    >
                      <v-avatar rounded size="100">
                        <img :src="getAvatar(details.logo)" :alt="details.companyName" />
                      </v-avatar>
                    </div>
                    <div v-else class="d-flex align-end">
                      <v-avatar rounded color="grey" size="100">
                      </v-avatar>
                    </div>
                  </v-row>
                  <v-row v-if="isAdmin || isCustomerService">
                    <div class="d-flex align-start mt-5">
                      <v-checkbox
                        class="d-flex align-end"
                        v-model="details.inDebtCollectionProcess"
                        hide-details
                        outlined
                        :label="$t('companiesDetails.debtCollectionProcess')"
                        :disabled="!$permission('CompanyMarkDebtCollectionProcess')"
                        @change="markDebtCollectionProcess()"
                      >
                      </v-checkbox>
                    </div>
                  </v-row>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-text>

                <v-row dense>
                  <v-col lg="6" sm="12">
                    <label> {{ $t('companiesDetails.companyId') }} </label>
                    <v-text-field
                      :value="details.displayId"
                      outlined
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col lg="6" sm="12">
                    <label> {{ $t('companiesDetails.lang') }} </label>
                    <v-select
                      v-model="details.companyLanguage"
                      :items="languages"
                      item-text="label"
                      item-value="value"
                      dense
                      outlined
                      disabled
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col lg="6" sm="12">
                    <label>{{ $t('companiesDetails.payerStatus') }}</label>
                    <v-select
                      v-model="details.payerStatus"
                      :items="companyPayerStatuses"
                      item-value="id"
                      item-text="description"
                      class="text-left"
                      outlined
                      dense
                      :hint="dateOfPayerStatusChange"
                      persistent-hint
                      :disabled="!$permission('CompanyChangePayerStatus')"
                      @change="changePayerStatus"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-text>
                <p class="font-weight-bold">{{ $t('companiesDetails.header') }}</p>
                <v-form
                  ref="updateProfileForm"
                  v-model="valid"
                  :disabled="submitLoading"
                >
                  <label>{{ $t('companiesDetails.taxNumber') }}</label>
                  <v-text-field
                    v-model="details.vatNumber"
                    outlined
                    dense
                    class="w-50"
                    :rules="required"
                  ></v-text-field>
                  <label>{{ $t('companiesDetails.companyName') }}</label>
                  <v-text-field
                    v-model="details.companyName"
                    outlined
                    dense
                    :rules="required"
                  ></v-text-field>
                  <label>{{ $t('companiesDetails.street') }}</label>
                  <v-text-field
                    v-model="details.street"
                    outlined
                    dense
                    :rules="required"
                  ></v-text-field
                  ><label>{{ $t('companiesDetails.zipCode') }}</label>
                  <v-text-field
                    v-model="details.zipCode"
                    class="w-50"
                    outlined
                    dense
                    :rules="[...required, maxCharInZipCode]"
                  ></v-text-field>
                  <label>{{ $t('companiesDetails.city') }}</label>
                  <v-text-field
                    v-model="details.city"
                    class="w-50"
                    outlined
                    dense
                    :rules="required"
                  ></v-text-field>
                  <v-row>
                    <v-col sm="5" md="4" lg="3" xl="2">
                      <label>{{ $t('companiesDetails.country') }}</label>
                      <CountryFlagSelect v-model="details.country"/>
                    </v-col>
                  </v-row>
                  <label>{{ $t('companiesDetails.apiName') }}</label>
                  <v-text-field
                    v-model="details.apiName"
                    class="w-50"
                    outlined
                    dense
                  ></v-text-field>

                </v-form>
                <div class="d-flex py-5">
                  <v-btn
                    color="info"
                    outlined
                    class="ml-auto"
                    :disabled="submitLoading"
                    :loading="submitLoading"
                    @click="updateDetails"
                  >
                    {{ $t('common.update') }}
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card class="h-100">
              <v-card-text>
                <p class="font-weight-bold">{{ $t('companiesDetails.contact') }}</p>
                <label>{{ $t('companiesDetails.ownerName') }}</label>
                <v-text-field
                  :value="details.userName"
                  outlined
                  dense
                  disabled
                ></v-text-field>
                <label>{{ $t('companiesDetails.surname') }}</label>
                <v-text-field
                  :value="details.surname"
                  outlined
                  dense
                  disabled
                ></v-text-field>
                <label>{{ $t('companiesDetails.ownerPhone') }}</label>
                <v-text-field
                  :value="details.phone"
                  outlined
                  dense
                  disabled
                ></v-text-field
                ><label>{{ $t('companiesDetails.email') }}</label>
                <v-text-field
                  :value="details.email"
                  outlined
                  dense
                  disabled
                ></v-text-field>
                <div class="mt-8"></div>
                <label>{{ $t('companiesDetails.language') }}</label>
                <v-select
                  :items="[
                    {
                      label: $t(`languages.${details.companyLanguage}`),
                      code: details.companyLanguage,
                    },
                  ]"
                  v-model="details.companyLanguage"
                  item-text="label"
                  item-value="code"
                  class="w-50"
                  dense
                  outlined
                  disabled
                ></v-select>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
  </div>
</template>
<script>
import Service from '@/services'
import { mapGetters } from 'vuex'
import { fileUrl, dateFormat, maxCharInZipCode } from '@/utils/helpers'
import CountryFlagSelect from '@/components/CountryFlagSelect'

export default {
  name: 'Information',
  components: {
    CountryFlagSelect
  },
  data () {
    return {
      loading: true,
      valid: true,
      submitLoading: false,
      languages: [],
      details: null,
      required: [
        (v) => (!!v && !!v.trim()) || this.$t('validation.fieldRequired')
      ],
      maxCharInZipCode
    }
  },
  async created () {
    await this.getDetails()
    await this.fetchLanguages()
  },
  computed: {
    resignUserDialogTitle () {
      return this.$t('saveIssue.resignDialogTitle')
    },
    ...mapGetters({
      isAdmin: 'isAdmin',
      isCustomerService: 'isCustomerService'
    }),
    companyPayerStatuses () {
      return this.$store.getters.enums.companyPayerStatus
    },
    dateOfPayerStatusChange () {
      if (this.details.dateOfPayerStatusChange) {
        return `${this.$t('companiesDetails.dateOfPayerStatusChange')}
        ${dateFormat(this.details.dateOfPayerStatusChange)} `
      } else {
        return ''
      }
    }
  },
  methods: {
    getAvatar (url) {
      return fileUrl(url)
    },
    async getDetails () {
      this.loading = true

      try {
        const { data } = await Service.get(
          '/Company/GetCompanyDetails',
          { companyId: this.$route.params.id }
        )
        this.details = data
      } catch (e) {}

      this.loading = false
    },
    async fetchLanguages () {
      const { data } = await Service.get('Frontend/GetAvailableLanguages')
      this.lang = data
      this.languages = data.map(language => {
        return {
          value: language,
          label: this.$t(`languages.${language}`)
        }
      })
    },
    async updateDetails () {
      this.$refs.updateProfileForm.validate()
      if (!this.valid) {
        return
      }
      this.submitLoading = true

      try {
        await Service.put('/CustomerService/UpdateCompanyData', {
          companyId: this.$route.params.id,
          vatNumber: this.details.vatNumber,
          name: this.details.companyName,
          street: this.details.street,
          zipCode: this.details.zipCode,
          city: this.details.city,
          country: this.details.country,
          apiName: this.details.apiName
        })
        this.$refs.updateProfileForm.resetValidation()
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('companiesDetails.updateProfile')
        )
      } catch (e) {}
      this.submitLoading = false
    },
    async changePayerStatus () {
      try {
        await Service.put('CustomerService/ChangePayerStatus', {
          companyId: this.$route.params.id,
          payerStatus: this.details.payerStatus
        })

        await this.$store.dispatch('setSuccessNotification',
          this.$t('common.confirmOperation'))
        await this.getDetails()
      } catch (e) {}
    },
    async markDebtCollectionProcess () {
      try {
        await Service.put('CustomerService/MarkDebtCollectionProcess', {
          companyId: this.$route.params.id,
          inDebtCollectionProcess: this.details.inDebtCollectionProcess
        })

        await this.$store.dispatch('setSuccessNotification',
          this.$t('common.confirmOperation'))
        await this.getDetails()
      } catch (e) {}
    }
  }
}
</script>

<style scoped>
.v-messages {
  text-align: left !important;
}
</style>
