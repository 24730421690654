<template>
  <v-card v-if="summary" flat>
    <v-card-text>
      <span class="body-1">{{ $t('reviews.rate') }}</span>
      <div class="d-flex align-center mb-4 mt-1">
        <span class="font-weight-bold primary--text mr-2 pt-1">{{ summary.generalRate.average.toFixed(1) }}</span>
        <v-rating length="5" size="16" color="accent" readonly  :value="+summary.generalRate.average" class="mr-2" />
        <span class="pt-1">{{ summary.generalRate.reviewsCount }} {{ $t('reviews.grades') }} / {{ summary.generalRate.companiesReviewedCount }}  {{ $t('reviews.companies') }}</span>
      </div>
      <span class="body-1">{{ $t('reviews.rates') }}</span>
      <div class="mt-1" style="width: 350px">
        <v-row v-for="(item, name) in summary" :key="name" no-gutters>
          <template v-if="item.average > 0">
            <v-col cols="5">
              <span class="body-2">{{ $t(`reviews.${name}`) }}</span>
            </v-col>
            <v-col cols="4" align-self="end">
              <v-rating length="5" size="16" color="accent" readonly :value="+item.average" class="mb-1 d-flex justify-end" />
            </v-col>
            <v-col cols="3">
              <span class="font-weight-bold primary--text ml-2">{{ item.average.toFixed(1) }}</span>
            </v-col>
          </template>
        </v-row>
      </div>
    </v-card-text>
    <v-divider />
    <v-card-text>
      <v-form ref="form">
        <v-row align="center">
          <v-col cols="12" md="3">
            <label>{{ $t('reviews.place') }}</label>
            <v-text-field v-model="searchInputValue" prepend-inner-icon="$magnify" outlined dense single-line hide-details="auto" />
          </v-col>
          <v-col cols="12" md="2">
            <DatePicker v-model="params.dateFrom" :label="$t('filters.dateFrom')" />
          </v-col>
          <v-col cols="12" md="2">
            <DatePicker v-model="params.dateTo" :label="$t('filters.dateTo')" />
          </v-col>
          <v-col align-self="center">
            <v-radio-group v-model="params.type" row hide-details class="mt-0 pt-3">
              <v-radio :label="$t('reviews.received')" :value="2" />
              <v-radio :label="$t('reviews.given')" :value="1" />
            </v-radio-group>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider />
    <data-table
    ref="reviews"
    :headers="headers"
    endpoint='Reviews/GetCompanyReviewsCustomerSerivce'
    :additionalParams="{ companyId: $route.params.id, dateFrom: params.dateFrom, dateTo: params.dateTo, type: params.type }"
    :search="searchInputValue"
    serverPagination>
      <template v-slot:item.sideOfTransaction="{ item }">
        <div v-if="item.sideOfTransaction === 1" class="d-flex align-center">
          <v-icon size="26">$provider</v-icon>
          <div class="ml-4">
            {{ $t('reviews.provider') }}
          </div>
        </div>
        <div v-else class="d-flex align-center">
          <v-icon size="26">$client</v-icon>
          <div class="ml-4">
            {{ $t('reviews.client') }}
          </div>
        </div>
      </template>
      <template v-slot:[`item.review`]="{ item }">
        <v-tooltip v-if="item.review" bottom min-width="310" max-width="310">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="d-flex align-center">
              <span class="font-weight-bold primary--text mr-2 pt-1">{{ item.review.generalRate.toFixed(1) }}</span>
              <v-rating length="5" size="16" color="accent" readonly :value="+item.review.generalRate" class="mr-2" />
            </div>
            <span v-if="item.review" class="text">{{ item.review.comment | trimText(25) }}</span>
          </template>
          <v-row class="py-4">
            <v-col cols="6">
              <div class="body-2 mb-2">{{ $t('reviews.comment') }}</div>
              <div class="comment body-2">
                {{ item.review.comment | trimText(250) }}
              </div>
            </v-col>
            <v-col cols="6">
              <div class="body-2 mb-2">{{ $t('reviews.title') }}</div>
              <div v-for="(rate, name) in item.review" :key="name">
                <div v-if="rate && (name !== 'generalRate' && name !== 'comment')" class="body-2 mb-2">
                  {{ $t(`reviews.${name}`) }}
                  <v-rating length="5" size="16" color="accent" readonly :value="+rate" />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-tooltip>
        <div v-else>
          {{ $t('reviews.none') }}
        </div>
      </template>
      <template v-slot:[`item.forwarder`]="{ item }">
        <span class="text">{{ item.forwarder.fullName }}</span>
      </template>
      <template v-slot:item.loadingPlaces="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex align-center">
            <CountryFlag :country='item.loadingPlaces[0].country' class="mr-1"/>
            <div class="d-flex flex-column">
              <span>
                {{ item.loadingPlaces[0].country.toUpperCase() }}, <span>{{ item.loadingPlaces[0].postalCode }}</span> <br/>
                {{ item.loadingPlaces[0].city }}
              </span>
              <div class="lightText--text">
                <span>{{ item.loadingPlaces[0].date | dateFormat }} </span>
                <span v-if="item.loadingPlaces[0].from && item.loadingPlaces[0].to">{{ item.loadingPlaces[0].from }} - {{ item.loadingPlaces[0].to }}</span>
              </div>
              <div v-if="item.loadingDistanceFromFilter">
                <span>{{ item.loadingDistanceFromFilter }}</span>
              </div>
            </div>
          </div>
          <v-tooltip v-if="item.loadingPlaces.length > 1" bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="more-data ml-2"> +{{ item.loadingPlaces.length - 1 }} </div>
            </template>
            <div v-for="(location, index) in item.loadingPlaces" :key="index">
              <div v-if="index !== 0" class="d-flex align-center" style="line-height: 1.25">
                <CountryFlag :country="location.country" class="mr-1" />
                <div class="d-flex flex-column">
                  <span>{{ location.country.toUpperCase() }}, {{ location.postalCode }} {{ location.city }}</span>
                  <span>{{ location.date | dateFormat }}<span v-if="location.from && location.to"> {{ location.from }} - {{ location.to }}</span></span>
                </div>
              </div>
            </div>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.unloadingPlaces="{ item }">
        <div v-if="item.unloadingPlaces.length > 0" class="d-flex align-center">
          <div class="d-flex align-center">
            <CountryFlag :country='item.unloadingPlaces[0].country' class="mr-1"/>
            <div class="d-flex flex-column">
              <div>
                <span>
                  {{ item.unloadingPlaces[0].country.toUpperCase() }}, {{ item.unloadingPlaces[0].postalCode }}<br/>
                  {{ item.unloadingPlaces[0].city }}
                </span>
                <div class="lightText--text">
                  <span>{{ item.unloadingPlaces[0].date | dateFormat }} </span>
                  <span v-if="item.unloadingPlaces[0].from && item.unloadingPlaces[0].to">{{ item.unloadingPlaces[0].from }} - {{ item.unloadingPlaces[0].to }}</span>
                </div>
                <div v-if="item.unloadingDistanceFromFilter">
                  <span>{{ item.unloadingDistanceFromFilter }}</span>
                </div>
              </div>
            </div>
          </div>
          <v-tooltip v-if="item.unloadingPlaces.length > 1" bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="more-data ml-2">+{{ item.unloadingPlaces.length - 1 }}</div>
            </template>
            <div v-for="(location, index) in item.unloadingPlaces" :key="index">
              <div v-if="index !== 0" class="d-flex align-center" style="line-height: 1.25">
                <CountryFlag :country="location.country" class="mr-1" />
                <div class="d-flex flex-column">
                  <span>{{ location.country.toUpperCase() }}, {{ location.postalCode }} {{ location.city }}</span>
                  <span>{{ location.date | dateFormat }} <span v-if="location.from && location.to">{{ location.from }} - {{ location.to }}</span></span>
                </div>
              </div>
            </div>
          </v-tooltip>
        </div>
        <div v-else class="ml-2">
          {{ $t('myVehicle.anyDestination') }}
        </div>
      </template>
      <template v-slot:item.reviewDate="{ item }">
        <div class="text-center">
          {{ item.reviewDate | dateFormat('DD.MM.YYYY') }} <br/>
          {{ item.reviewDate | dateFormat('HH:mm') }}
        </div>
      </template>
      <template v-slot:item.dealDate="{ item }">
        <div class="text-center">
          {{ item.dealDate | dateFormat('DD.MM.YYYY') }} <br/>
          {{ item.dealDate | dateFormat('HH:mm') }}
        </div>
      </template>
      <template v-slot:item.reviewStatus="{ item }">
        {{ item.reviewStatus | getDescription($store.getters.enums.reviewStatuses) }}
      </template>
      <template v-slot:item.contractor="{ item }">
        <div v-if="item.contractor" class="d-flex align-center cursor-pointer">
          <StatusAvatar :userId="item.contractor.id" :size="26" showStatus class="mr-2"></StatusAvatar>
          <div class="d-flex flex-column">
            <div>{{ item.contractor.name }} {{ item.contractor.surname }}</div>
            <v-tooltip bottom min-width="320px">
              <template v-slot:activator="{ on, attrs }">
                <div class="lightText--text" v-bind="attrs" v-on="on">{{ item.contractor.company.name | trimText(20) }}</div>
              </template>
              <v-row class="mt-1">
                <v-col cols="6">
                  <p>{{ item.contractor.company.name }}<br />
                  NIP: {{ item.contractor.company.vatNumber }}</p>
                  <p>{{ $t('offerDetails.address') }}:<br />
                  {{ item.contractor.company.street }}<br />
                  {{ item.contractor.company.zipCode }}, {{ item.contractor.company.city }}</p>
                </v-col>
                <v-col cols="6">
                  {{ $t('offerDetails.averageRating') }}:
                  <v-rating length="5" size="16" color="accent" :value="+item.contractor.company.averageRating" class="mb-4"></v-rating>
                  {{ $t('offerDetails.paymentRating') }}:
                  <v-rating length="5" size="16" color="accent" :value="+item.contractor.company.averagePaymentRating"></v-rating>
                </v-col>
              </v-row>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:[`item.chatIcon`]="{ item }">
        <v-hover v-slot="{ hover }">
          <v-icon v-if="item.contractor" icon @click.stop="startConversationWithUser(item)" :color="hover ? 'primary' : ''" size="18">$conversation</v-icon>
        </v-hover>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu bottom left min-width="120">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-if="item.reviewStatus == 0">
              <v-list-item @click="remove(item)">{{ $t('common.remove') }}</v-list-item>
              <v-list-item @click="edit(item)">{{ $t('common.edit') }}</v-list-item>
            </template>
            <template v-else>
              <v-list-item @click="restore(item)">{{ $t('common.restore') }}</v-list-item>
            </template>
            <v-list-item v-if="item.actionsHistory.length > 0" @click="history(item.actionsHistory)">{{ $t('common.history') }}</v-list-item>
          </v-list>
        </v-menu>
      </template>
    </data-table>
    <Confirm ref="confirm" />
    <History ref="history" />
    <ReviewClient ref="reviewClient" />
    <ReviewProvider ref="reviewProvider" />
  </v-card>
</template>
<script>
import Service from '@/services'
import DatePicker from '@/components/forms/DatePicker'
import CountryFlag from 'vue-country-flag'
import StatusAvatar from '@/components/StatusAvatar'
import { startConversation } from '@/signalr/chat'
import Confirm from '@/components/dialogs/Confirm.vue'
import ReviewClient from '@/views/Reviews/dialogs/ReviewClient.vue'
import ReviewProvider from '@/views/Reviews/dialogs/ReviewProvider.vue'
import History from './dialogs/History.vue'
export default {
  name: 'Ratings',
  components: {
    DatePicker,
    CountryFlag,
    StatusAvatar,
    Confirm,
    ReviewClient,
    ReviewProvider,
    History
  },
  data () {
    return {
      params: {
        dateFrom: null,
        dateTo: null,
        type: 2
      },
      summary: null,
      searchInputValue: '',
      sideOfTransaction: [
        { id: 1, description: this.$t('reviews.provider') },
        { id: 2, description: this.$t('reviews.client') }
      ],
      headers: [
        {
          text: this.$t('reviews.state'),
          value: 'sideOfTransaction',
          sortable: false
        },
        {
          text: this.$t('reviews.tabRate'),
          sortable: false,
          value: 'review'
        },
        {
          text: this.$t('reviews.forwarder'),
          value: 'forwarder'
        },
        {
          text: this.$t('reviews.loading'),
          value: 'loadingPlaces'
        },
        {
          text: this.$t('reviews.unloading'),
          value: 'unloadingPlaces'
        },
        {
          text: this.$t('reviews.date'),
          align: 'center',
          value: 'dealDate'
        },
        {
          text: this.$t('reviews.created'),
          align: 'center',
          value: 'reviewDate'
        },
        {
          text: this.$t('reviews.contractor'),
          align: 'start',
          sortable: false,
          value: 'contractor'
        },
        {
          value: 'chatIcon',
          width: '10',
          sortable: false
        },
        {
          text: 'Status',
          align: 'center',
          value: 'reviewStatus',
          sortable: false
        },
        {
          text: '',
          align: 'center',
          value: 'lastActionPerformer.fullName',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          width: '75',
          sortable: false
        }
      ]
    }
  },
  async created () {
    await this.getDetails()
  },
  methods: {
    async getDetails () {
      this.loading = true

      try {
        const { data } = await Service.get(`/Reviews/GetReviewsSummary?companyId=${this.$route.params.id}`)
        this.summary = data
      } catch (e) {}

      this.loading = false
    },
    startConversationWithUser (row) {
      startConversation(row.contractor.id, row.orderId)
    },
    async remove (review) {
      this.loadingDialog = true
      if (await this.$refs.confirm.open(this.$t('reviews.remove'), this.$t('profile.devices.title'))) {
        try {
          await Service.delete('/Reviews/DeleteReview', {
            reviewId: review.reviewId
          })
          this.$refs.reviews.getData()
          await this.$store.dispatch(
            'setSuccessNotification',
            this.$t('common.confirmOperation')
          )
        } catch (e) {}
      }
      this.loadingDialog = false
    },
    async restore (review) {
      this.loadingDialog = true
      if (await this.$refs.confirm.open(this.$t('reviews.restore'), this.$t('profile.devices.title'))) {
        try {
          await Service.put('/Reviews/RestoreReview', {
            reviewId: review.reviewId
          })
          await this.$store.dispatch(
            'setSuccessNotification',
            this.$t('common.confirmOperation')
          )
          this.$refs.reviews.getData()
        } catch (e) {}
      }
      this.loadingDialog = false
    },
    history (actionsHistory) {
      this.$refs.history.open(actionsHistory)
    },
    edit (item) {
      if (item.reviewType === 1) {
        this.$refs.reviewProvider.edit({ ...item.review, reviewId: item.reviewId })
      } else {
        this.$refs.reviewClient.edit({ ...item.review, reviewId: item.reviewId })
      }
    }
  }
}
</script>
<style>
</style>
