var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.history)?_c('v-dialog',{attrs:{"persistent":"","width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.history,"show-expand":"","expanded":_vm.expanded,"single-expand":"","item-key":"date","expand-icon":"$sort"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-2",attrs:{"colspan":headers.length}},[_c('p',[_vm._v(_vm._s(_vm.$t('reviews.comment'))+":")]),_vm._v(" "+_vm._s(item.commentBeforeAction)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.date,'DD.MM.YYYY HH:mm'))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDescription")(item.type,_vm.$store.getters.enums.reviewActionTypes))+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('reviews.history'))+" ")]},proxy:true}],null,false,865917698)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('common.close'))+" ")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }