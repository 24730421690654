<template>
  <div>
    <v-card-text>
      <v-data-table
        ref="Documents"
        :headers="headers"
        :items="displayDocuments"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        hide-default-footer
        show-expand
        single-expand
      >
        <template v-slot:item.type="{ item }">
          {{ item.type | getDescription(documentTypes) }}
        </template>
        <template v-slot:item.validTo="{ item }">
          {{ item.validTo | dateFormat("DD.MM.YYYY") }}
        </template>
        <template v-slot:item.dateOfDeprecation="{ item }">
          {{ item.dateOfDeprecation | dateFormat("DD.MM.YYYY") }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td class="pa-6" :colspan="headers.length">
            <v-row>
              <v-col cols="4">
                <div class="d-flex flex-column ml-8">
                  <div
                    v-for="file in item.files"
                    :key="file.name"
                    class="d-flex align-center mb-4"
                  >
                    <h4>{{ file.name }}</h4>
                    <v-icon
                      color="blue"
                      class="ml-auto pointer"
                      small
                      @click="getFile(file.name)"
                      >mdi-download</v-icon
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-if="$permission('DeleteFiles')"
            color="primary"
            outlined
            @click="openDeleteDialog(item)"
          >
            {{ $t('common.remove') }}
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog v-model="deleteDialog" persistent max-width="500px">
        <v-card class="py-4">
          <v-card-title>
          <h3 class="primary--text mb-2">
                    {{ $t("companiesDetails.removeFile") }}
                  </h3></v-card-title>
          <v-card-actions class="px-8">
            <v-spacer></v-spacer>
            <v-btn
              color="secodary"
              outlined
              depressed
              class="mr-2"
              :disabled="loading"
              @click="closeDeleteDialog"
            >
              {{ $t('common.cancel')}}
            </v-btn>
            <v-btn
              color="primary"
              depressed
              :loading="loading"
              @click="deleteFile"
            >
              {{ $t('common.remove')}}
            </v-btn>
          </v-card-actions>
        </v-card>
       </v-dialog>

      <v-pagination
        v-if="pageCount > 1"
        :value="options.page"
        :length="pageCount"
        @input="options.page = $event"
      ></v-pagination>
    </v-card-text>
  </div>
</template>
<script>
import Service from '@/services'

export default {
  name: 'Archive',
  data () {
    return {
      loading: true,
      total: 0,
      options: {},
      headers: [
        {
          text: this.$t('companiesDetails.type'),
          align: 'start',
          value: 'type',
          sortable: false
        },
        {
          text: this.$t('companiesDetails.date'),
          value: 'validTo',
          sortable: false
        },
        {
          text: this.$t('companiesDetails.name'),
          value: 'description',
          sortable: false
        },
        {
          text: this.$t('companiesDetails.user'),
          value: 'name',
          sortable: false
        },
        {
          text: this.$t('companiesDetails.deprecation'),
          value: 'dateOfDeprecation',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          align: 'end'
        }
      ],
      documents: [],
      selectedDocument: {},
      deleteDialog: false
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.total / this.options.itemsPerPage)
    },
    documentTypes () {
      return this.$store.getters.enums.documentTypes
    },
    usersState () {
      return this.$store.getters['users/users']
    },
    displayDocuments () {
      return this.documents.map((document) => {
        const u = this.usersState.find(
          (item) => item.id === document.depreciatedBy
        )
        if (!u) {
          return document
        }

        document.name = `${u.name} ${u.surname}`

        return document
      })
    }
  },
  watch: {
    options: {
      handler () {
        this.getDocuments()
      },
      deep: true
    }
  },
  methods: {
    update () {
      console.log('emituje!')
      this.$emit('update')
    },
    async deleteFile () {
      this.loading = true
      try {
        await Service.delete('/CustomerService/DeleteCompanyDocument', {
          documentId: this.selectedDocument.id,
          companyId: this.$route.params.id
        })
      } catch (e) {
      } finally {
        this.loading = false
        this.closeDeleteDialog()
      }
      await this.getDocuments()
      this.update()
    },
    openDeleteDialog (doc) {
      this.deleteDialog = true
      this.selectedDocument = doc
    },
    closeDeleteDialog () {
      this.deleteDialog = false
      this.selectedDocumentId = null
    },
    refresh () {
      this.$refs.Documents.getData()
    },
    async getDocuments () {
      this.loading = true
      const { page, itemsPerPage } = this.options

      try {
        const { data } = await Service.getList(
          `/CustomerService/GetArchivedCompanyDocuments?companyId=${this.$route.params.id}`,
          page,
          itemsPerPage
        )
        this.documents = data.results
        this.total = data.count
      } catch (e) {}

      this.loading = false
    },
    async getFile (file) {
      try {
        const { data } = await Service.getFile(
          '/CustomerService/GetCompanyDocumentFile',
          {
            companyId: this.$route.params.id,
            fileName: file
          }
        )

        const blob = new Blob([data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = file
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (e) {}
    }
  }
}
</script>
<style scoped>
</style>
