var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.issues,"server-items-length":_vm.total,"loading":_vm.loading,"hide-default-footer":"","show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"item.orderingCompanyUserId",fn:function(ref){
var item = ref.item;
return [(item.orderingCompanyUser)?_c('div',[_vm._v(" "+_vm._s(item.orderingCompanyUser.name)+" "+_vm._s(item.orderingCompanyUser.surname)+" ")]):_vm._e()]}},{key:"item.linkedCompanyUserId",fn:function(ref){
var item = ref.item;
return [(item.linkedCompanyUser)?_c('div',[_vm._v(" "+_vm._s(item.linkedCompanyUser.name)+" "+_vm._s(item.linkedCompanyUser.surname)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("issues.notApplicable"))+" ")])]}},{key:"item.linkedCompanyDisplayId",fn:function(ref){
var item = ref.item;
return [(item.linkedCompanyDisplayId)?_c('div',[_vm._v(" "+_vm._s(item.linkedCompanyDisplayId)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("issues.notApplicable"))+" ")])]}},{key:"item.dateOfRegistration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dateOfRegistration))+" ")]}},{key:"item.issueState",fn:function(ref){
var item = ref.item;
return [(item.depreciated)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("issues.closed"))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.getState(item.issueState))+" ")])]}},{key:"item.currentlyHandledBy",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('div',[_vm._v(" "+_vm._s(item.user.name)+" "+_vm._s(item.user.surname)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-6",attrs:{"colspan":headers.length}},[_c('h4',[_vm._v(_vm._s(_vm.$t("issues.issueState")))]),_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.detailsHeaders,"items":item.assignmentHistory},scopedSlots:_vm._u([{key:"item.department",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDepartment(item.department))+" ")]}},{key:"item.dateOfAssignment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dateOfAssignment))+" ")]}},{key:"item.assignedTo",fn:function(ref){
var item = ref.item;
return [(item.assignedTo)?_c('div',[_vm._v(" "+_vm._s(item.assignedTo.name)+" "+_vm._s(item.assignedTo.surname)+" ")]):_vm._e()]}}],null,true)})],1)]}}],null,true)}),(_vm.pageCount > 1)?_c('v-pagination',{attrs:{"value":_vm.options.page,"length":_vm.pageCount},on:{"input":function($event){_vm.options.page = $event}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }