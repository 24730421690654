<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>{{ $t("users.dialogs.unblockUser.title") }}</v-card-title>
      <v-card-text>
        <v-container>
          <p
            class="mb-8"
          >
               {{$t("users.dialogs.unblockUser.header.name")}}
          <strong>{{user.name}} {{user.surname}} - {{user.email}}</strong>
          {{$t("users.dialogs.unblockUser.header.text")}}</p>
          <label>{{ $t("users.dialogs.unblockUser.reason") }}:</label>
          <p>{{ user.blockReason }}</p>
          <label>{{ $t("users.dialogs.unblockUser.date") }}:</label>
          <p>{{ user.blockingDate | dateFormat("DD.MM.YYYY HH:mm") }}</p>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secodary"
          outlined
          class="mr-2"
          :disabled="loading"
          @click="$emit('cancel')"
        >
          {{ $t("users.dialogs.unblockUser.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="$emit('unblock')"
        >
          {{ $t("users.dialogs.unblockUser.unblock") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'UnblockUser',
  props: ['dialog', 'user', 'loading'],
  computed: {
    usersState () {
      return this.$store.getters['users/users']
    },
    blockedBy () {
      const user = this.usersState.find((item) => item.id === this.user.blockedBy)
      return user ? `${user.name} ${user.surname}` : ''
    }
  },
  methods: {}
}
</script>
