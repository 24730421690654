<template>
  <div>
    <v-card-title
      ><v-spacer></v-spacer
      ><v-btn
        color="primary"
        outlined
        class="mb-2"
        :disabled="addNew"
        @click="addNew = !addNew"
      >
        {{ $t('companiesDetails.addDoc') }}
      </v-btn></v-card-title
    >
    <v-card-text>
      <v-row>
        <v-expansion-panels
          v-if="addNew"
          v-model="newDocumentPanel"
          multiple
        >
          <v-col cols="12">
            <v-expansion-panel
              v-model="newDocumentPanel"
              class="ma-3"
            >
            <v-expansion-panel-header>
            </v-expansion-panel-header>
              <v-expansion-panel-content>
                <NewDocument
                  class="pa-10"
                  :type="documentType"
                  :loading="loading"
                  @submit="submitHandler"
                ></NewDocument>
              </v-expansion-panel-content>
          </v-expansion-panel>
        </v-col>
        </v-expansion-panels>
      </v-row>
      <v-row>
        <v-expansion-panels
          v-model="documentPanel"
          multiple
        >
          <v-col cols="12">
            <v-expansion-panel
              v-model="documentPanel"
              v-for="document in displayDocuments" :key="document.id"
              class="ma-3"
            >
              <v-expansion-panel-header>
                {{ document.description }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                  <DocumentItem
                    class="pa-10"
                    :document="document"
                    :loading="loading"
                    @verify="documentVerify"
                    @update="documentUpdate"
                    @remove="documentRemove"
                  ></DocumentItem>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-col>
        </v-expansion-panels>
      </v-row>
    </v-card-text>
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
  </div>
</template>
<script>
import Service from '@/services'
import NewDocument from './DocumentNew.vue'
import DocumentItem from './DocumentItem.vue'

export default {
  name: 'DocumentsList',
  props: {
    documentType: {
      type: Number,
      required: true
    }
  },
  components: { NewDocument, DocumentItem },
  data () {
    return {
      loading: true,
      addNew: false,
      documents: [],
      documentPanel: [0],
      newDocumentPanel: [0]
    }
  },
  computed: {
    usersState () {
      return this.$store.getters['users/users']
    },
    displayDocuments () {
      return this.documents.map((document) => {
        const u = this.usersState.find(
          (item) => item.id === document.verifiedBy
        )
        if (!u) {
          return document
        }

        document.name = u.name
        document.surname = u.surname

        return document
      })
    }
  },
  created () {
    this.getDocuments()
  },
  methods: {
    update () {
      this.$emit('update')
    },
    async getDocuments () {
      this.loading = true

      try {
        const { data } = await Service.get(
          '/CustomerService/GetCompanyDocuments',
          {
            companyId: this.$route.params.id,
            documentType: this.documentType
          }
        )
        this.documents = data
      } catch (e) {}
      this.loading = false
    },
    async documentVerify (document) {
      this.loading = true

      try {
        await Service.put('/CustomerService/MarkCompanyDocument', document)
        await this.getDocuments()
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('companiesDetails.verify')
        )
      } catch (e) {}

      this.loading = false
    },
    async documentUpdate (document) {
      this.loading = true

      try {
        await Service.put('/CustomerService/UpdateCompanyDocument', document)
        await this.getDocuments()
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('companiesDetails.update')
        )
      } catch (e) {}

      this.loading = false
    },
    async documentRemove (document) {
      this.loading = true

      try {
        await Service.put('/CustomerService/ArchiveCompanyDocument', document)
        await this.getDocuments()
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('companiesDetails.remove')
        )
        this.update()
      } catch (e) {}

      this.loading = false
    },
    async submitHandler (document) {
      this.loading = true
      var formData = new FormData()
      formData.append('CompanyId', this.$route.params.id)

      const documentIndex = 0
      for (const property in document) {
        if (property === 'files') {
          document[property].forEach((file) => {
            formData.append(
              `Documents[${documentIndex}].FilesWrapper.Files`,
              file,
              file.name
            )
          })
        } else {
          formData.append(
            `Documents[${documentIndex}].${property}`,
            document[property]
          )
        }
      }

      try {
        await Service.post('/CustomerService/UploadCompanyDocument', formData)
        this.addNew = false
        await this.getDocuments()
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('companiesDetails.add')
        )
        this.update()
      } catch (e) {}

      this.loading = false
    }
  }
}
</script>
<style scoped>
</style>
