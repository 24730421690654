<template>
  <v-card flat>
    <v-tabs v-model="tab" :height="60">
      <v-tab> {{ $t('companiesDetails.policy') }} ({{ documents.policiesCount }}) </v-tab>
      <v-tab> {{ $t('companiesDetails.licence') }}  ({{ documents.licenciesCount }}) </v-tab>
      <v-tab> {{ $t('companiesDetails.certificate') }}  ({{ documents.certificatesCount }}) </v-tab>
      <v-tab> {{ $t('companiesDetails.other') }}  ({{ documents.othersCount }}) </v-tab>
      <v-tab> {{ $t('companiesDetails.archive') }}  ({{ documents.archivedCount }}) </v-tab>
      <v-tab> {{ $t('companiesDetails.rodo') }} </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <DocumentsList v-if="tab == 0" @update="getDocumentsCount" :documentType="types.policy"></DocumentsList>
      </v-tab-item>
      <v-tab-item>
        <DocumentsList v-if="tab == 1" @update="getDocumentsCount" :documentType="types.licence"></DocumentsList>
      </v-tab-item>
      <v-tab-item>
        <DocumentsList v-if="tab == 2" @update="getDocumentsCount" :documentType="types.certificate"></DocumentsList>
      </v-tab-item>
      <v-tab-item>
        <DocumentsList v-if="tab == 3" @update="getDocumentsCount" :documentType="types.other"></DocumentsList>
      </v-tab-item>
      <v-tab-item>
        <Archive v-if="tab == 4" @update="getDocumentsCount"></Archive>
      </v-tab-item>
      <v-tab-item>
        <Rodo></Rodo>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import Service from '@/services'
import Archive from './Archive.vue'
import DocumentsList from './DocumentsList.vue'
import Rodo from './Rodo.vue'
import * as type from '@/types/document-types'

export default {
  name: 'Documents',
  components: { Archive, DocumentsList, Rodo },
  data () {
    return {
      tab: null,
      types: {
        policy: type.POLICY,
        licence: type.LICENCE,
        certificate: type.CERTIFICATE,
        other: type.OTHER
      },
      documents: {
        archivedCount: 0,
        certificatesCount: 0,
        licenciesCount: 0,
        othersCount: 0,
        policiesCount: 0
      }
    }
  },
  created () {
    this.getDocumentsCount()
  },
  methods: {
    async getDocumentsCount () {
      this.loading = true

      try {
        const { data } = await Service.get(
          '/CustomerService/GetCompanyDocumentCount',
          {
            companyId: this.$route.params.id
          }
        )
        this.documents = data
      } catch (e) {}
      this.loading = false
    }
  }
}
</script>
<style scoped>
.v-tab {
  text-transform: none;
}
</style>
